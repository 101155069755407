<template>
	<div ref="formContainer">
		<AddHeaderTitle :Header="Header" />
		<AppHeaderInfoAdminList :infos="infos" />

		<b-row align-h="start" v-if="adminRole == 1">
			<b-button
				variant="primary "
				class="mb-2 mr-2"
				v-b-modal.modal-viewer-create
			>
				<feather-icon icon="PlusIcon" class="mr-50" />
				<span class="align-left">Créer un participant </span>
			</b-button>
		</b-row>

		<b-table striped :items="viewers" :fields="fields">
			<template #cell(pre_quizz_check)="list">
				<span v-if="list.item.pre_quizz >= 0">
					<b-avatar variant="light-success" size="34">
						<feather-icon icon="CheckCircleIcon" size="25" />
					</b-avatar>
				</span>
				<span v-else>
					<b-avatar variant="light-warning" size="34">
						<feather-icon icon="XCircleIcon" size="25" />
					</b-avatar>
				</span>
			</template>

			<template #cell(post_quizz_check)="list">
				<span v-if="list.item.post_quizz >= 70">
					<b-avatar variant="light-success" size="34">
						{{ list.item.post_quizz }}%
					</b-avatar>
				</span>
				<span v-else-if="list.item.post_quizz < 0">
					<b-avatar variant="light-secondary" size="34">
						<feather-icon icon="XCircleIcon" size="25" />
					</b-avatar>
				</span>
				<span v-else-if="isSpeaker(list.item.id)">
					<b-avatar variant="light-secondary" size="34">
						<feather-icon icon="XCircleIcon" size="25" />
					</b-avatar>
				</span>
				<span v-else>
					<b-avatar variant="light-warning" size="34">
						{{ list.item.post_quizz }}%
					</b-avatar>
				</span>
			</template>

			<template #cell(survey)="list">
				<span v-if="list.item.survey == true">
					<b-avatar variant="light-success" size="34">
						<feather-icon icon="CheckSquareIcon" size="25" />
					</b-avatar>
				</span>
				<span v-else>
					<b-avatar variant="light-warning" size="34">
						<feather-icon icon="XCircleIcon" size="25" />
					</b-avatar>
				</span>
			</template>

			<template #cell(presence_percentage)="list">
				{{ getPercent(list.item.presence_percentage) }} %
			</template>

			<template #cell(action)="list">
				<b-dropdown
					v-if="!isSpeaker(list.item.id)"
					variant="link"
					toggle-class="p-0"
					no-caret
					right
				>
					<template #button-content>
						<feather-icon
							icon="MoreVerticalIcon"
							size="16"
							class="align-middle text-body"
						/>
					</template>
					<b-dropdown-item
						@click="editViewer(list.item)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="EditIcon" />
						Editer
					</b-dropdown-item>

					<b-dropdown-item
						@click="genAccess(list.item)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="EditIcon" />
						Générer accès
					</b-dropdown-item>

					<b-dropdown-item
						v-if="list.item.pdf_cfc != null"
						variant="flat-success"
						size="sm"
						@click="openCertif(list.item.pdf_cfc)"
					>
						<feather-icon icon="DownloadCloudIcon" />
						certificat cfc
					</b-dropdown-item>
					<b-dropdown-item
						v-if="list.item.pdf_ceu != null"
						variant="flat-success"
						size="sm"
						@click="openCertif(list.item.pdf_ceu)"
					>
						<feather-icon icon="DownloadCloudIcon" />
						certificat ceu
					</b-dropdown-item>
					<b-dropdown-item
						v-if="list.item.pdf_presence != null"
						variant="flat-success"
						size="sm"
						@click="openCertif(list.item.pdf_presence)"
					>
						<feather-icon icon="DownloadCloudIcon" />
						certificat Présence
					</b-dropdown-item>
					<b-dropdown-item
						variant="flat-primary"
						size="sm"
						@click="generateCertificate(list.item)"
					>
						<feather-icon icon="AwardIcon" />
						Générer certificat
					</b-dropdown-item>
					<b-dropdown-item
						@click="sendPreQuizz(list.item)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="SendIcon" />
						Envoyer pre-quizz
					</b-dropdown-item>
					<b-dropdown-item
						v-if="list.item.post_quizz < 70"
						@click="sendQuizz(list.item)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="SendIcon" />
						Renvoyer post-quizz
					</b-dropdown-item>
					<b-dropdown-item
						v-if="list.item.survey == 0"
						@click="sendSurvey(list.item)"
						variant="flat-danger"
						size="sm"
						class=""
					>
						<feather-icon icon="SendIcon" />
						Renvoyer Survey
					</b-dropdown-item>
				</b-dropdown>
				<span v-if="isSpeaker(list.item.id)" class="align-left">(Speaker)</span>
			</template>
		</b-table>
		<b-modal
			v-model="modalEditViewer"
			title="Editer"
			ok-title="Editer"
			cancel-variant="outline-success"
			ok-variant="success"
			cancel-title="Annuler"
			centered
			size="lg"
			@ok="editViewerHandler"
		>
			<b-form>
				<b-row>
					<b-col>
						<label>Prénom</label>
						<b-form-input v-model="viewerData.firstname"></b-form-input>
					</b-col>
					<b-col>
						<label>Nom</label>
						<b-form-input v-model="viewerData.lastname"></b-form-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label>Mail</label>
						<b-form-input v-model="viewerData.email"></b-form-input>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<label>Numéro CEU</label>
						<b-form-input v-model="viewerData.number_ceu"></b-form-input>
					</b-col>
					<b-col>
						<label>Numéro CFC</label>
						<b-form-input v-model="viewerData.number_cfc"></b-form-input>
					</b-col>
					<b-col>
						<label>Temps de présence (en %)</label>
						<b-form-input
							v-model="viewerData.presence_percentage"
						></b-form-input>
					</b-col>
					<b-col>
						<label>Aba online id</label>
						<b-form-input v-model="viewerData.aba_online_id"></b-form-input>
					</b-col>
				</b-row>
			</b-form>
		</b-modal>

		<b-modal
			v-model="modalForceSignIn"
			title="jeton unique d'authentification"
			ok-title="copier"
			cancel-variant="outline-success"
			ok-variant="success"
			cancel-title="Annuler"
			centered
			size="lg"
			@ok="copyForceSignIn"
		>
			<h3>Founir l'url suivant (usage unique attention) au participant</h3>
			<b-row>
				<b-col>
					
					<b-form-input
						style="max-width: 100%;"
						v-on:focus="$event.target.select()"
						ref="valueSignIn"
						readonly
						:value="textSignIn"
					/>
				</b-col>
			</b-row>
		</b-modal>
		<ViewerCreateModal @clicked="onCreate"> </ViewerCreateModal>
	</div>
</template>

<script>
import AddHeaderTitle from "@/app/views/components/AppHeaderTitle.vue";
import AppHeaderInfoAdminList from "@/app/views/components/AppHeaderInfoAdminList.vue";
import {
	BTable,
	BAvatar,
	BDropdown,
	BDropdownItem,
	BCol,
	BModal,
	BRow,
	BForm,
	BFormInput,
	BButton,
} from "bootstrap-vue";
import { url } from "@/services/axios";
import { Admin } from "@/mixins/admin.js";
import ViewerCreateModal from "./viewer-create-modal.vue";

export default {
	name: "viewers",

	components: {
		ViewerCreateModal,
		BRow,
		BCol,
		BFormInput,
		BTable,
		BButton,
		BForm,
		BModal,
		BAvatar,
		BDropdown,
		BDropdownItem,
		AddHeaderTitle,
		AppHeaderInfoAdminList,
	},

	data() {
		return {
			Header: {
				config: "Admin",
				listLink: "/admin/webinar/list",
				list: "Webinar-liste",
				detailLink: "",
				detail: "viewer-liste",
			},
			infos: {
				title: "Liste des participants",
				content: "Liste des participants liés à un webinar",
			},
			url: url,
			viewerData: {
				number_ceu: "",
				number_cfc: "",
				firstname: "",
				lastname: "",
				email: "",
				id: 0,
				presence_percentage: "",
				aba_online_id: "",
			},
			speaker: {},
			statusArray: [
				{
					status: true,
					badge: "warning",
					text: "Actif",
				},
				{
					status: false,
					badge: "danger",
					text: "Inactif",
				},
			],
			modalEditViewer: false,
			list: [],
			// Note 'isActive' is left out and will not appear in the rendered table
			fields: [
				{
					key: "lastname",
					label: "Nom",
					sortable: true,
				},
				{
					key: "firstname",
					label: "Prénom",
					sortable: true,
				},
				{
					key: "email",
					label: "Email",
					sortable: true,
				},
				{
					key: "number_ceu",
					label: "CEU",
					sortable: true,
				},
				{
					key: "number_cfc",
					label: "CFC",
					sortable: true,
				},
				{
					key: "pre_quizz_check",
					label: "pré-quizz",
					sortable: false,
				},

				{
					key: "post_quizz_check",
					label: "post quizz",
					sortable: true,
				},

				{
					key: "survey",
					label: "survey",
					sortable: true,
				},
				{
					key: "presence_percentage",
					label: "temps de présence",
					sortable: true,
				},
				{
					key: "Action",
					label: "Action",
					sortable: false,
				},
			],
			busy: false,
			processing: false,
			modalForceSignIn: false,
			textSignIn: "",
			idWebinar: this.$route.params.id,
			viewers: [],
			adminRole: this.$store.state.user.user.role,
		};
	},

	mixins: [Admin],

	mounted() {
		this.loadViewers();
	},
	computed: {
		v_number_cfc() {
			console.log(this.viewerData?.number_cfc);
			return this.viewerData?.number_cfc ? this.viewerData.number_cfc : "";
		},
	},

	methods: {
		setDataSpeaker(speakerData) {
			this.speakerData = speakerData;
		},
		isSpeaker(id) {
			if (id.length == 36) {
				return true;
			}
			return false;
		},
		onCreate(value) {
			console.log(value);
			this.viewers.push({ ...value, presence_percentage: 0 });
		},
		openCertif(id) {
			window.open(`${url}media/${id}`);
		},
		async loadViewers() {
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const response = await this.getViewersByWebinarId(this.idWebinar);
				if (response.status === 1) {
					this.viewers = response.datas;
				} else {
					this.$sweetError("LOAD-VIEWER");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("LOAD-VIEWER-1");
			}
		},

		editViewer(viewer) {
			this.viewerData = viewer;
			this.modalEditViewer = true;
			console.log(this.viewerData);
		},
		async editViewerHandler() {
			try {
				const response = await this.editViewersById(
					this.viewerData.id,
					this.viewerData
				);
				if (response.status === 1) {
					this.$sweetNotif("Viewer updated");
				} else {
					this.$sweetError("Edit Viewer");
				}
			} catch {}
		},

		async genAccess(viewer) {
			this.modalForceSignIn = true;
			console.log("🚀 ~ genAccess ~ viewer:", viewer);
			try {
				const payload = {
					webinar_id: this.idWebinar,
					id: +viewer.aba_online_id,
					status: 1,
				};
				const response = await this.forceSignIn(payload);
				console.log("🚀 ~ genAccess ~ response:", response);
				if (response.status === 1) {
					this.textSignIn = response.datas.redirect;
					this.$sweetNotif("Viewer updated");
				} else {
					this.$sweetError("Edit Viewer");
				}
			} catch (error) {}
		},
		copyForceSignIn() {
			this.$refs.valueSignIn.focus();
			document.execCommand("copy");
			this.$sweetNotif("Copied");
		},
		sendInfo(speakerInfo) {
			this.speaker = speakerInfo;
		},

		async generateCertificate(item) {
			const currentIndex = this.viewers.indexOf(item);
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const datas = await this.postGenerateCertificate(
					this.idWebinar,
					item.id
				);
				if (datas.status === 1) {
					for (const file of datas.datas.files) {
						switch (file.type) {
							case "CEU":
								item.pdf_ceu = file.mediaId;
								break;
							case "CFC":
								item.pdf_cfc = file.mediaId;
								break;
							case "PRESENTIAL":
								item.pdf_presence = file.mediaId;
								break;
						}
					}
					this.viewers.splice(currentIndex, 1, item);
					this.$sweetNotif("certificate rebuilt");
				} else {
					this.$sweetError("LOAD-CERTIFICATE");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("LOAD-CERTIFICATE-1");
			}
		},

		async sendQuizz(item) {
			console.log(item);
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const datas = await this.sendViewerPostQuizz(item.id, this.idWebinar);
				if (datas.status == 1) {
					this.$sweetNotif("Envoi post-quizz réussi");
				} else {
					this.$sweetError("SEND-QUIZZ");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("SEND-QUIZZ-1");
			}
		},

		async sendPreQuizz(item) {
			console.log(item);
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const datas = await this.sendViewerPreQuizz(item.id, this.idWebinar);
				if (datas.status == 1) {
					this.$sweetNotif("Envoi pre-quizz réussi");
				} else {
					this.$sweetError("SEND-QUIZZ");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("SEND-QUIZZ-2");
			}
		},

		async sendSurvey(item) {
			console.log(item);
			let loader = this.$loading.show({
				container: this.$refs.formContainer,
				canCancel: true,
			});
			try {
				const datas = await this.sendViewerSurvey(item.id, this.idWebinar);
				if (datas.status == 1) {
					this.$sweetNotif("Envoi survey réussi");
				} else {
					this.$sweetError("SEND-QUIZZ");
				}
				loader.hide();
			} catch {
				loader.hide();
				this.$sweetError("SEND-QUIZZ-2");
			}
		},

		getPercent(value) {
			return Math.round(value);
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
