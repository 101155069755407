<template>
  <div>

    <b-modal 
      ref="modal-viewer-create" 
      id="modal-viewer-create" 
      title="Créer un participant"
    >

      <ValidationObserver ref="observer">
        <b-form>
          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Nom </label>
            <b-form-input v-model="form.lastname" placeholder="Dupont"></b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required" v-slot="{ errors }">
            <label> Prénom </label>
            <b-form-input v-model="form.firstname" placeholder="Henri">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="required|email" v-slot="{ errors }">
            <label> Email </label>
            <b-form-input v-model="form.email" placeholder="henri.dupont@mail.com">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider  v-slot="{ errors }">
            <label> Numéro CEU </label>
            <b-form-input v-model="form.number_ceu" placeholder="0-12-34567">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider  v-slot="{ errors }">
            <label> Numéro CFC </label>
            <b-form-input v-model="form.number_cfc" placeholder="#ACC-A-12-3456">
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />

          <ValidationProvider rules="" v-slot="{ errors }">
            <label> AbaOnline Id (recommandé) </label>
            <b-form-input v-model="form.aba_online_id" placeholder=112>
            </b-form-input>
            <small class="text-danger">{{ errors[0] }}</small>
          </ValidationProvider>
          <br />
        </b-form>
      </ValidationObserver>

      <template #modal-footer>
        <div class="w-100">
          <b-button variant="primary" size="sm" class="float-right ml-1" @click="onSubmit">
            Créer
          </b-button>
          <b-button variant="danger" size="sm" class="float-right" @click="hideModal">
            Fermer
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  BForm,
  BFormInput,
} from "bootstrap-vue";
import { Admin } from "@/mixins/admin.js";

import { ValidationObserver, ValidationProvider, extend } from "vee-validate";
import * as rules from "vee-validate/dist/rules";

Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

extend("required", { message: "Ce champs ne peut pas être vide" });
extend("email", { message: "Ce champs doit être un email valide" });

export default {
  name: "ViewerCreateModal",

  components: {
    BModal,
    BButton,
    BForm,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
  },

  mixins: [Admin],

  data() {
    return {
      id: this.$route.params.id,
      show: false,
      form: {
        lastname: "",
        firstname: "",
        email: "",
        number_ceu: "",
        number_cfc: "",
        id: this.$route.params.id,
        aba_online_id:"",
      },

      photo: "",
      currentImage: undefined,
      progress: 0,
      //  optionsStatus: [
      //   { key: false, value: 0 },
      //   {  key: true, value: 1 }
      // ],
    };
  },

  mounted() { },

  methods: {
    async onFileInput(event) {
      this.currentImage = event;
      const data = URL.createObjectURL(event.target.files[0]);
      this.photo = data;
    },

    async onSubmit() {

      const isValid = await this.$refs.observer.validate();
      if (isValid) {

        let loader = this.$loading.show({
          container: this.$refs.formContainer,
          canCancel: true,
        });

        try {
          const response = await this.createViewer(this.form);
          if (response.status == 0) {
            //@dev alert pour dire qu'il y a eu un probleme
            console.log("erreur");
          } else {
            loader.hide();
            this.$emit("clicked", response.datas);
            this.hideModal();
            this.$sweetNotif("Ajout réussi");
            this.form = {
              lastname: "",
              firstname: "",
              email: "",
              number_ceu: "",
              number_cfc: "",
              id: this.$route.params.id,
              aba_online_id:"",
            }
          }
        } catch {
          loader.hide();
          this.$sweetError("VC-01");
        }
      }
    },

    hideModal() {
      this.form = {};
      this.photo = "";
      this.currentImage = undefined
      this.$refs["modal-viewer-create"].hide();
    },
  },

  computed: {},
};
</script>

<style>
</style>
